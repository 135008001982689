import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const CategoryList = ({ onSelectCategory }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          'https://product.xianelec.com/wp-json/wc/v3/products/categories',
          {
            params: {
              consumer_key: 'ck_7b491d41bf607c4cc5493ad10e2e87848a2492d8',
              consumer_secret: 'cs_bb313c9fa98b4dae360cdc9f7fb45e14931b25da',
              lang: 'en',
            },
          }
        );

        setCategories(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (categoryId) => {
    onSelectCategory(categoryId);
    navigate(`/?category=${categoryId}`);
  };

  return (
    <div>
      <h2>INDEX</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <ul>
          {categories.map((category) => (
            <li key={category.id}>
              <button onClick={() => handleCategoryClick(category.id)}>{category.name}</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CategoryList;
