const translations = {
  ابعاد: "Dimensions",
  "رنگ-نور": "Color-Light",
  "تعداد-کلید": "Key Count",
  "ولتاژ-ورودی": "Input Voltage",
  "مشخصات-رله": "Relay Specifications",
  "روشنایی-فر": "Brightness-Range",
  "جوجه-گردان": "Rotisserie",
  "قطع-کن-شعله": "Flame Breaker",
  "قفل-کودک": "Child Lock",
  "نوع-نمایشگر": "Display Type",
  "جنس-قاب": "Frame Material",
  "نوع-کلید": "Switch Type",
  "دمای-کاری": "Operating Temperature",
  "کنترل-شعله": "Flame Control",
  "کنترل-فن": "Fan Control",
  "تایمر-یادآوری": "Reminder Timer",
  "قطعی-برق": "Power Cut-off",
  "نمایشگر-زمان": "Time Display",
  "دمای-فر": "Oven Temperature",
  "رنگ-قاب": "Frame Color",
  "دور-فن": "Fan Speed",
  "برد-ریموت-کنترل": "Remote Control Range",
  "کنترل-لامپ": "Lamp Control",
  "ضخامت-شیشه": "Glass Thickness",
  "نوع-ریموت-کنترل": "Remote Control Type",
  "حالت-اتومات": "Automatic Mode",
  "تایمر-خاموشی": "Shutdown Timer",
  "برنامه-پخت": "Cooking Program",
  "قطع-دوقطبی": "Double Pole Switch",
  دیجیتالی: "Digital",
  سرعت: "Speed",
  "صرفه-جویی": "Energy Saving",
  "نمایشگر-دما": "Temperature Display",
  "واسط-کاربری": "User Interface",
  "درجه-ترموستات": "Thermostat Level",
  "0-85°C": "0-85°C",
  "نوع-موتور": "Motor Type",
  "فیوز-محافظ": "Fuse Protector",
  پمپ: "Pump",
  "روشن-و-خاموش": "On and Off",
  "کنترل-دمای": "Temperature Control",
  "ریموت-کنترل": "Remote Control",
  "led-تزئینی": "Decorative LED",
  "محافظ-پمپ": "Pump Protector",
  "ورودی-ولتاژ": "Input Voltage",
  "خروجی-کمپرسور": "Compressor Output",
  "تعداد-سنسور-درب": "Door Sensor Count",
  "خروجی-uv": "UV Output",
  "فن-bldc": "BLDC Fan",
  "دمپر-dc": "DC Damper",
  "دمپر-ac": "AC Damper",
  "جریان-مصرفی": "Power Consumption",
  "قابلیت اینترنت اشیا (IOT)": "Internet of Things (IoT) Capability",
  "تعداد-سیم-دیتا": "Data Wire Count",
  "کنترل-برودت": "Cooling Control",
  "بدون-برفک": "Frost-Free",
  "سرمایش-و-انجماد": "Cooling and Freezing",
  یخساز: "Ice Maker",
  آبسردکن: "Water Dispenser",
  "سیستم-صرفه-جویی": "Energy Saving System",
  "عیب-یاب": "Fault Detector",
  "باز-ماندن": "Stay Open",
  "هشدار-دما": "Temperature Alert",
  "تعویض-فیلتر": "Filter Replacement",
  "نمایش-ایراد": "Error Display",
  "ایراد-سنسورها": "Sensor Errors",
  "ایراد-تراز": "Level Errors",
  "قابلیت-کنترل": "Control Capability",
  "پارامتر-کنترلی": "Control Parameters",
  "تجهیزات-جانبی": "Accessories",
  "نوع-پاور": "Power Type",
  "تعداد المنت‌های گرمکن": "Number of Heating Elements",
  تایمر: "Timer",
  "نوع-تغذیه": "Power Supply Type",
  "نوع-جرقه-زدن": "Ignition Type",
  "داخل-فر": "Inside the Oven",
  "گرمایش-سریع": "Quick Heating",
  "المنت-پایین": "Bottom Element",
  "کنترل-گریل": "Grill Control",
  "فن-خنک-کننده": "Cooling Fan",
  "حالت-دو-طبقه": "Two-level Mode",
  "پروب-گوشت": "Meat Probe",
  "المنت-بالا": "Top Element",
  "المنت-رینگی": "Ring Element",
  "سایر-ویژگی-ها": "Other Features",
  "برنامه-شستشو": "Wash Program",
  "پیش-شستشو": "Pre-wash",
  "کنترل-المنت": "Element Control",
  "220-ولت": "220 Volts",
  "تعداد-سنسور-دما": "Number of Temperature Sensors",
  "خروجی-شیر-برقی": "Electric Valve Output",
  "سرویس-َمد": "Ebb Service",
  "سرعت-موتور": "Motor Speed",
  "پیگیری-برنامه": "Program Tracking",
  "شیر آب سرد، شیر آب گرم": "Cold Water Valve, Hot Water Valve",
  "کردن-لباس": "Fabric Treatment",
  "سرعت-دور": "Speed RPM",
  "کنترل-لباسشویی": "Laundry Control",
  "تنظیم-آب": "Water Adjustment",
  "میزان-آب": "Water Level",
  "شیر-آب-گرم": "Hot Water Valve",
  "تایمر-شستشو": "Wash Timer",
  "تشخیص-خرابی": "Fault Detection",
  "لامپ-led": "LED Lamp",
  "خروجی-هیتر": "Heater Output",
  "محافظ-ولتاژ": "Voltage Protector",
  "سازگار-با-قاب": "Compatible with Frame",
  "موتور-القایی": "Induction Motor",
  دوقطبی: "Bipolar",
  "نوع-لامپ": "Lamp Type",
  "خروجی-پانل": "Panel Output",
  "ارتباط-نمایشگر": "Display Connection",
 "ایزولاتور": "Isolator",
  "نوع-کلید-هشدار": "Alarm Key Type",
  "عملکرد-هشدار": "Alarm Function",
  "آشکارسازی-خطا": "Error Detection",
  "led-کلید-هشدار": "Alarm LED",
  "ترمز-ناگهانی": "Emergency Brake",
  "چراغهای-راهنما": "Guide Lights",
  "جریان-بی-باری": "No-load Current",
  "نوع-شبکه": "Network Type",
"نرخ-سرعت-شبکه": "Network Speed Rate",
"کنترل-چراغ-اصلی": "Main Light Control",
"کنترل-تهویه": "Ventilation Control",
"کنترل برف‌پاک‌کن‌‌ها": "Snowplow Control",
"خوانش سنسور روغن ترمز": "Brake Oil Sensor Reading",
"خوانش سنسور فشار روغن": "Oil Pressure Sensor Reading",
"خوانش سنسور ترمز دستی": "Handbrake Sensor Reading",
"خوانش و پایش ولتاژ باتری و آلترناتور": "Battery and Alternator Voltage Reading and Monitoring",
"قابلیت تنظیم و بروزرسانی نرم افزار": "Software Adjustment and Updating Capability",
"خوانش سنسور دنده عقب": "Reverse Gear Sensor Reading",
"قابلیت بروزرسانی از طریق دستگاه دیاگ": "Updating Capability via Diagnostic Device",
"تنظیم خودکار جریان مصرفی لامپ‌ها": "Automatic Adjustment of Lamp Power Consumption",
"سیستم ایمنی کنترل لامپ‌ها": "Lamp Control Safety System",
"فن-ac": "AC Fan",
"فن-dc": "DC Fan",
};

export default translations;
