import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import logo from "./logo.png"; // Importing logo from the src directory
import translations from "./translations"; // Import translations file
import './App.css'; // Import your CSS file

const ProductList = ({ selectedCategory }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let url = "https://product.xianelec.com/wp-json/wc/v3/products";

        const params = {
          consumer_key: "ck_7b491d41bf607c4cc5493ad10e2e87848a2492d8",
          consumer_secret: "cs_bb313c9fa98b4dae360cdc9f7fb45e14931b25da",
          lang: "en",
          per_page: 100,
        };

        if (selectedCategory) {
          params.category = selectedCategory;
        }

        if (id) {
          params.sku = id;
        }

        const response = await axios.get(url, { params });

        if (response.data.length === 0) {
          setNotFound(true);
        } else {
          setProducts(response.data);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };

    fetchProducts();
  }, [selectedCategory, id]);

  return (
    <div>
      <main className="content">
        {loading ? (
          <p>Loading...</p>
        ) : notFound ? (
          <p>No product found with that SKU.</p>
        ) : (
          <div>
            {products.map((product, index) => (
              <div key={product.id} className="product-card" style={{ pageBreakBefore: "always" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0",
                    alignItems: "center",
                    justifyContent: "space-around",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    backgroundColor:"#F8941C",
                    color: "white",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <img width="250px" src={logo} alt="Logo" />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      fontSize:'20px',
                      fontWeight:'bold',
                      justifyContent: "space-around",
                    }}
                  >
                    <span>
                      {product.name}
                      <span style={{ fontSize: "0.8em", marginLeft: "10px" }}></span>
                    </span>
                    <span style={{ fontSize: "0.4em", marginLeft: "0px" }}>
                      {product.categories.length > 0 &&
                        product.categories[0].name}
                    </span>
                  </div>
                </div>
                <section
                  style={{
                    marginBottom: "20px",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "20px",
                    border: "1px solid #ccc",
                    backgroundColor: "white",
                  }}
                >
                  <div style={{ display: "flex",alignItems:'center',justifyContent:"center",flexWrap: "wrap" }}>
                    {product.images &&
                      product.images.length > 0 &&
                      product.images.map((image, index) => (
                        <img
                          key={index}
                          src={image.src}
                          alt={product.name}
                          style={{
                            width: "20%",
                            margin: "5px",
                            borderStyle:'solid',
                            borderRadius:'5px',
                            borderWidth:'1px'
                           }}
                        />
                      ))}
                  </div>
                  <div style={{ padding: "10px" }}>
                    <div dangerouslySetInnerHTML={{ __html: product.description }} />
                    <table style={{ borderCollapse: "collapse", width: "100%" ,borderStyle:'solid',borderWidth:'2px',borderColor:'#F8941C'}}>
                      <tbody>
                        {product.attributes.map((attribute, index) => {
                          // Calculate the index for the left and right columns
                          const leftColumnIndex = index * 2;
                          const rightColumnIndex = leftColumnIndex + 1;
                          
                          return (
                            <tr key={index} style={{ borderBottom: "1px solid #ccc" }}>
                              {/* Left Column */}
                              {product.attributes[leftColumnIndex] && (
                                <>
                                  <td style={{ padding: "0px", textAlign: "left" ,backgroundColor:'#F8941C',color:'white',textAlign:'center'}}>
                                    <strong className={index === 0 ? 'attribute-title' : ''}>
                                      {translations[product.attributes[leftColumnIndex].name] || product.attributes[leftColumnIndex].name}
                                    </strong>
                                  </td>
                                  <td style={{ padding: "0px", textAlign: "left" ,paddingLeft:'20px'}}>
                                    {product.attributes[leftColumnIndex].options.join(", ")}
                                  </td>
                                </>
                              )}

                              {/* Right Column */}
                              {product.attributes[rightColumnIndex] && (
                                <>
                                  <td style={{ padding: "0px", textAlign: "left" ,backgroundColor:'#F8941C',color:'white',textAlign:'center'}}>
                                    <strong className={index === 0 ? 'attribute-title' : ''}>
                                      {translations[product.attributes[rightColumnIndex].name] || product.attributes[rightColumnIndex].name}
                                    </strong>
                                  </td>
                                  <td style={{ padding: "0px", textAlign: "left" ,paddingLeft:'20px'}}>
                                    {product.attributes[rightColumnIndex].options.join(", ")}
                                  </td>
                                </>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            ))}
          </div>
        )}
      </main>
    </div>
  );
};

export default ProductList;
