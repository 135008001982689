import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CategoryList from './CategoryList';
import './App.css'; // Importing the CSS file
import ProductList from './ProductList';

const App = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <Router>
      <div  style={{ fontFamily: "'Kalameh-Regular', sans-serif" }}>
        <CategoryList  onSelectCategory={setSelectedCategory} />
        <Routes>
          <Route path="/" element={<ProductList selectedCategory={selectedCategory} />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
